import './App.scss';
import Footer from './pages/Footer/Footer';
import Header from './pages/Header/Header';
import Home from './pages/Home/Home';
import Contact from './pages/contact/Contact';

function App() {
  return (
    <div className="App">
      <Header/>
      <Home/>
      <Contact/>
      <Footer/>
    </div>
  );
}

export default App;
