import { Box, Card, Container, Dialog } from '@mui/material'
import { BrowserRouter, Link, NavLink } from 'react-router-dom'
import imagecompress from '../../assets/img/image-compress.svg';
import ProjectViewer from '../../components/project-viewer/ProjectViewer';

import './Home.scss'




export default function Home() {

  const data = require('../../assets/projects/Projects.json');
  console.log(data);

  return (
    <div className='Home'>
      <ProjectViewer projects={data.projects}/>
    </div>
  )
}