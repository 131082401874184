import { Container } from '@mui/material';
import './Footer.scss';
import logo from '../../assets/img/logo.svg';


export default function Footer() {
  return (
    <div className='Footer'>
        <section >
            <img src={logo} alt='Logo'></img>
            <small>© 2023 by Code Crafted. All rights reserved.</small>
        </section>
    </div>
  )
}
