
import './Contact.scss';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import emailjs from '@emailjs/browser';
import { Circles, CirclesWithBar } from 'react-loader-spinner';


// import loader from '../../assets/icons/light/loader.svg';

export default function Contact() {

    const [fname, setFname] = useState('');
    const [lname, setLname] = useState('');
    const [email, setEmail] = useState('');
    const [messege, setMessege] = useState('');
    const [success, setSuccess] = useState('');
    const [isSending, setSending] = useState(false);

    function getTemplate(form) {
        const templateParams = {
            "fname": form[0].value,
            "lname": form[1].value,
            "email": form[2].value,
            "message": form[3].value
        }
        return templateParams;
    }


    const sendEmail = (e) => {
        setSending(true);
        e.preventDefault();
        emailjs.send('service_e8oam8g', 'template_22fspfd', getTemplate(e.target), 'ihO1q38-DIyH5MRpz')
            .then((result) => {
                setSuccess("Your message is sent successfully.!");
                resetFrom();
                setSending(false);
            }, (error) => {
                console.log(error.text);
                setSending(false);
            });
    };

    const resetFrom = () => {
        setFname('');
        setLname('');
        setEmail('');
        setMessege('');
    }

    return (
        <div className="Contact bg-app" id="contact">
            <div className="c-cont padding">
                <div className="label">Contact</div>
                <div className="form-wrap">

                    <form onSubmit={sendEmail}>
                        <input type='text' required value={fname} onChange={(e) => setFname(e.target.value)} placeholder="First Name" />
                        <input type='text' required value={lname} onChange={(e) => setLname(e.target.value)} placeholder="Last Name" />
                        <input type='email' required value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Email" />
                        <textarea required value={messege} placeholder='Your message here...' onChange={(e) => setMessege(e.target.value)} />
                        <input type="submit" disabled={isSending} required value="Send" name="Send" />
                    </form>
                    <span className='success-messege'>{success}</span>

                </div>
                {isSending ?
                    <section className='loader-img'>
                        <CirclesWithBar visible={isSending} width={50} height={50} color={"#102C57"} ariaLabel='Sending...' wrapperClass='' />
                        <span>Sending...</span>
                    </section>
                    : null
                }

            </div>
        </div>
    )
}
