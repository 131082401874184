import { Card } from "@mui/material";
import { BrowserRouter, Link } from "react-router-dom";
// import image from '../../assets/projects/img/image-compress.svg';

import './ProjectViewer.scss';
import { useEffect, useRef, useState } from "react";
import { Icon } from "../SVGDynamicImport/SVGDynamicImport";

export default function ProjectViewer({projects}:{projects:any}) {

  const srcUrl = '../../assets/projects/img/';
  const [icons, setIcons] = useState<any>([]);


//   useEffect(() => {

//     projects.map((p:any) => {
//       const image = require(`${srcUrl}${p.src}`);
//       icons.push(image);
//     })

//   });

  return (
    <div className='ProjectViewer'>
      <BrowserRouter>
        {
          projects.map((p :any, i:number) => {

            return <Link to={p.url} target='_blank' key={i}>
              <img src={require(`../../assets/projects/img/${p.src}`)} alt={p.name} />
              <label>{p.name}</label>
            </Link>
          })
        }
      </BrowserRouter>
    </div>
  )
}
