import './Header.scss';
import logo from '../../assets/img/logo.svg';

export default function Header() {
  return (
    <header>
        <section className='logo'>
            <img src={logo} alt='Header logo'/>
            <label>CodeCrafted</label>
        </section>
    </header>
  )
}
